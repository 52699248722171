import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc, query, where, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './GetQuote.css';

const GetQuote = () => {
    const [quotes, setQuotes] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [newQuote, setNewQuote] = useState({
        origin: '',
        destination: '',
        specialInstructions: '',
        requestId: '' // New field for Request ID
    });
    const [userCompany, setUserCompany] = useState('');
    const [userRole, setUserRole] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch the current user's company to filter quotes
        const fetchUserCompany = async () => {
            const auth = getAuth();
            const currentUser = auth.currentUser;

            if (currentUser) {
                const userEmail = currentUser.email;
                const usersQuery = query(collection(db, 'users'), where('email', '==', userEmail));
                const userSnapshot = await getDocs(usersQuery);
                if (!userSnapshot.empty) {
                    const userData = userSnapshot.docs[0].data();
                    setUserCompany(userData.company);
                    setUserRole(userData.role);
                }
            }
        };

        fetchUserCompany();
    }, []);

    useEffect(() => {
        // Fetch active quotes for the current user's company
        const fetchQuotes = async () => {
            try {
                const quotesQuery = query(
                    collection(db, 'QuoteRequests'),
                    where('company', '==', userCompany),
                    where('status', '==', 'Active')
                );
                const querySnapshot = await getDocs(quotesQuery);
                const fetchedQuotes = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setQuotes(fetchedQuotes);
            } catch (error) {
                console.error('Error fetching quotes:', error);
            } finally {
                setLoading(false);
            }
        };

        if (userCompany) {
            fetchQuotes();
        }
    }, [userCompany]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewQuote({ ...newQuote, [name]: value });
    };

    const fetchQueryId = async (requestId) => {
        try {
            // Fetch the document directly by the document ID (which is the requestId in this case)
            const requestDocRef = doc(db, 'Requests', requestId);
            const requestDoc = await getDoc(requestDocRef);
    
            if (requestDoc.exists()) {
                const requestData = requestDoc.data();
                return requestData.query_id;  // Fetch and return the query_id from the document data
            } else {
                console.error('No document found with the given requestId.');
                return null;
            }
        } catch (error) {
            console.error('Error fetching query ID:', error);
            return null;
        }
    };
    

    const invokeLambda = async (documentId) => {
        const lambdaUrl = 'https://kxnhglr3uikn6ycsnrnuvvixbm0sxchi.lambda-url.ap-south-1.on.aws/';
        console.log('documentID:', documentId);
        try {
            // Trigger Lambda with the documentID
            await fetch(lambdaUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    documentID: documentId,  // Send document ID in the payload
                }),
            });
            console.log('Lambda invoked with documentID:', documentId);
        } catch (error) {
            console.error('Error invoking Lambda:', error);
        }
    };

    const handleSubmit = async () => {
        if (newQuote.origin && newQuote.destination && newQuote.requestId) {  // Ensure all fields are filled
            setLoading(true);
            try {
                // Fetch the query_id based on requestId
                const queryId = await fetchQueryId(newQuote.requestId);
                if (!queryId) {
                    alert('No query ID found for this request ID.');
                    setLoading(false);
                    return;
                }
                console.log(userRole)
                const newQuoteData = {
                    origin: newQuote.origin,
                    destination: newQuote.destination,
                    specialInstructions: newQuote.specialInstructions,
                    company: userCompany,
                    department: userRole,
                    status: 'Active',
                    date_created: new Date().toISOString(),
                    request_id: newQuote.requestId,
                    query_id: queryId  // Use fetched query ID
                };

                // Add the new quote request to the collection
                const docRef = await addDoc(collection(db, 'QuoteRequests'), newQuoteData);
                
                // Invoke Lambda with the new document ID
                await invokeLambda(docRef.id);

                // Refresh the quotes list after submitting the new request
                setQuotes(prevQuotes => [...prevQuotes, { id: docRef.id, ...newQuoteData }]);

                // Reset form and hide it
                setNewQuote({ origin: '', destination: '', specialInstructions: '', requestId: '' });
                setShowForm(false);
            } catch (error) {
                console.error('Error creating quote request:', error);
            } finally {
                setLoading(false);
            }
        } else {
            alert('Please fill in Origin, Destination, and Request ID fields.');
        }
    };

    const renderTable = () => {
        if (loading) {
            return <div>Loading...</div>;
        }

        if (quotes.length === 0) {
            return <p>No active quotes found for your company.</p>;
        }

        return (
            <table className="quote-table">
                <thead>
                    <tr>
                        <th>Quote Request ID</th>
                        <th>Request ID</th>
                        <th>Origin</th>
                        <th>Destination</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {quotes.map((quote) => (
                        <tr key={quote.id}>
                            <td>{quote.id}</td>
                            <td>{quote.request_id}</td>
                            <td>{quote.origin}</td>
                            <td>{quote.destination}</td>
                            <td>
                                <button
                                    className="view-button"
                                    onClick={() => navigate(`/quote-details/${quote.id}`)}
                                >
                                    View
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    return (
        <div className="get-quote-view">
            <h2>Quote Requests</h2>

            <div className="header-container">
                <button className="create-quote-button" onClick={() => setShowForm(true)}>
                    + Create New Quote Request
                </button>
            </div>

            {renderTable()}

            {showForm && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h3>Create a New Quote Request</h3>

                        <label>
                            Request ID:
                            <input
                                type="text"
                                name="requestId"
                                value={newQuote.requestId}
                                onChange={handleChange}
                                placeholder="Enter request ID"
                            />
                        </label>

                        <label>
                            Origin:
                            <input
                                type="text"
                                name="origin"
                                value={newQuote.origin}
                                onChange={handleChange}
                                placeholder="Enter origin"
                            />
                        </label>

                        <label>
                            Destination:
                            <input
                                type="text"
                                name="destination"
                                value={newQuote.destination}
                                onChange={handleChange}
                                placeholder="Enter destination"
                            />
                        </label>

                        <label>
                            Special Instructions:
                            <textarea
                                name="specialInstructions"
                                value={newQuote.specialInstructions}
                                onChange={handleChange}
                                placeholder="Enter any special instructions"
                            />
                        </label>

                        <button onClick={handleSubmit} className="submit-quote-button" disabled={loading}>
                            {loading ? 'Submitting...' : 'Create Quote Request'}
                        </button>
                        <button onClick={() => setShowForm(false)} className="cancel-button">Cancel</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default GetQuote;
