// src/firebase.js

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"; // Import for authentication
import { getFirestore } from "firebase/firestore"; // Import for Firestore

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDcVAwAoZij5QIhrpHCPxXal12Hzcvehwg",
  authDomain: "freightpilotai-production.firebaseapp.com",
  projectId: "freightpilotai-production",
  storageBucket: "freightpilotai-production.appspot.com",
  messagingSenderId: "135268541481",
  appId: "1:135268541481:web:8c78d34bb98e62338fe838",
  measurementId: "G-3TX53LHPMB"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and Firestore
const auth = getAuth(app);
const db = getFirestore(app); // Firestore initialization

export { auth, db }; // Export both auth and db
// Import the functions you need from the SDKs you need
