import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebase'; // Import your Firebase configuration
import { doc, getDoc } from 'firebase/firestore';
import './ChatWithAgent.css';

const ChatWithAgent = ({ agentId, quoteId }) => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [isSending, setIsSending] = useState(false); // Manage sending status
    const [successMessage, setSuccessMessage] = useState(''); // State for success message
    const currentUser = auth.currentUser;
    const userEmail = currentUser.email;

    // Fetch messages from Firestore
    useEffect(() => {
        const fetchMessages = async () => {
            try {
                console.log('Fetching messages for agentId:', agentId);
                console.log(userEmail);
                const chatDoc = doc(db, 'ChatWithAgents', agentId);
                const docSnapshot = await getDoc(chatDoc);
                if (docSnapshot.exists()) {
                    const data = docSnapshot.data();
                    const chatMessages = data.Messages || {};
                    // Convert messages from object to array for easier handling
                    const messagesArray = Object.entries(chatMessages).map(([key, msg]) => ({
                      
                        user: msg.User,
                        text: msg.Message,
                        timestamp: msg.UpdateTime
                    }));
                    console.log('Fetched messages:', messagesArray);
                    setMessages(messagesArray);
                } else {
                    console.warn('No document found for agentId:', agentId);
                }
            } catch (error) {
                console.error('Error fetching messages:', error);
            }
        };

        fetchMessages();
    }, [agentId]);

    const handleSendMessage = async () => {
        console.log('handleSendMessage called');
        if (newMessage.trim() === '' || !userEmail || isSending) {
            console.log('Empty message, invalid userEmail, or message is already being sent');
            return; // Don't send empty messages or if a message is already being sent
        }

        setIsSending(true); // Set sending status to true
        setSuccessMessage(''); // Clear any previous success message

        try {
            console.log('Sending message to Lambda with payload:', {
                agentId,
                quoteId,
                message: newMessage,
                userEmail
            });
            const lambdaUrl = "https://zxc3y3dk2oza7gg25ets3shjt40tdqhs.lambda-url.ap-south-1.on.aws/";
            // Send the request to the Lambda function
            const response = await fetch(lambdaUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "agent_id": agentId,
                    "quote_id": quoteId,
                    "message": newMessage,
                    "user_email": userEmail
                }),
            });

            console.log('Lambda response:', response);
            if (response.ok) {
                // If the Lambda request was successful, update local state to show the new message in UI
                const messageData = {
                    user: userEmail,
                    message: newMessage,
                    timestamp: new Date().toISOString(),
                };
                setMessages([...messages, messageData]);
                setNewMessage(''); // Clear input field
                //setSuccessMessage('Message sent successfully!'); // Set success message
            } else {
                // Handle the error case
                console.error('Error sending message to Lambda, status:', response.status);
                alert('Failed to send message. Please try again.');
            }
        } catch (error) {
            console.error('Error sending message:', error);
            alert('An error occurred while sending your message. Please try again.');
        } finally {
            setIsSending(false); // Reset sending status
        }
    };

    return (
        <div className="chat-container">
            <div className="chat-messages">
                {messages.map((message, index) => (
                    <div key={index} className={`chat-message ${message.user === userEmail ? 'sent' : 'received'}`}>
                        <div className="message-sender">{message.user}</div>
                        <div 
                            className="message-text"
                            dangerouslySetInnerHTML={{ __html: message.text }} // Render HTML content
                        ></div>
                    </div>
                ))}
            </div>
            <div className="chat-input">
                <input
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Type your message..."
                    className="input-field"
                    disabled={isSending} // Disable input if a message is being sent
                />
                <button onClick={handleSendMessage} className="send-button" disabled={isSending}>
                    {isSending ? 'Sending...' : 'Send'}
                </button>
            </div>
            {successMessage && <div className="success-message">{successMessage}</div>} {/* Show success message */}
        </div>
    );
};

export default ChatWithAgent;
