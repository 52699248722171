import React, { useState, useEffect } from 'react';
import { db,auth } from '../firebase'; // Ensure Firestore is imported
import { collection, query, where, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useParams } from 'react-router-dom';
import './RequestQuote.css';

const AgentDetails = () => {
    const currentUser = auth.currentUser;
    
    
    const { quoteId } = useParams();
    const [agents, setAgents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userCompany, setUserCompany] = useState('');
    const [userRole, setUserRole] = useState('');
    const [selectMode, setSelectMode] = useState(false); // State to manage selection mode
    const [selectedAgents, setSelectedAgents] = useState([]); // State to manage selected agents

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const auth = getAuth();
                const currentUser = auth.currentUser;
                console.log("email is",auth.currentUser.email)
                if (!currentUser) {
                    console.log("No user logged in");
                    return;
                }

                // Fetch user data based on email from 'users' collection
                const userDocRef = collection(db, 'users');
                const userSnapshot = await getDocs(query(userDocRef, where('email', '==', currentUser.email)));

                // Check if we found a user document
                if (!userSnapshot.empty) {
                    const userData = userSnapshot.docs[0].data();
                    setUserCompany(userData.company);
                    setUserRole(userData.role);
                } else {
                    console.log("User document not found");
                    return;
                }
            } catch (error) {
                console.error("Error fetching user details:", error);
            }
        };

        fetchUserDetails();
    }, []);

    useEffect(() => {
        const fetchAgents = async () => {
            if (userCompany && userRole) {
                try {
                    const agentsRef = collection(db, 'Agents');
                    const q = query(
                        agentsRef,
                        where('company', '==', userCompany),
                        where('role', '==', userRole)
                    );
                    const querySnapshot = await getDocs(q);
                    const agentList = querySnapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data()
                    }));
                    setAgents(agentList);
                } catch (error) {
                    console.error('Error fetching agents:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchAgents();
    }, [userCompany, userRole]);

    const handleSelectAgent = (agentId) => {
        if (selectedAgents.includes(agentId)) {
            // If already selected, remove it
            setSelectedAgents(selectedAgents.filter((id) => id !== agentId));
        } else {
            // Add to selected list
            setSelectedAgents([...selectedAgents, agentId]);
        }
    };

    const handleSendToAgents = async () => {
        try {
            // Prepare the payload
            const selectedAgentDetails = agents
                .filter(agent => selectedAgents.includes(agent.id))
                .map(agent => ({
                    name: agent.agent_name,
                    email: agent.agent_email,
                    company:agent.agent_company
                }));

            const payload = {
                documentID:quoteId, // Include the quoteId
                Agents: selectedAgentDetails, // Include the selected agents,
                user:auth.currentUser.email
            };

            // Send the payload to the Lambda function
            const response = await fetch('https://k7vmy2mft5l3s26czy2rbpmp6e0bfquw.lambda-url.ap-south-1.on.aws/', {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                console.log('Agents sent successfully:', selectedAgentDetails);
            } else {
                console.error('Failed to send agents:', response.statusText);
            }
        } catch (error) {
            console.error('Error sending agents:', error);
        } finally {
            // Reset selection state
            setSelectedAgents([]);
            setSelectMode(false);
        }
    };

    const handleCancelSelection = () => {
        setSelectedAgents([]);
        setSelectMode(false);
    };

    if (loading) {
        return <div>Loading agent details...</div>;
    }

    if (agents.length === 0) {
        return <div>No agents found for your company and role.</div>;
    }

    return (
        <div className="agents-details-view">
            <h2>Agent Details</h2>

            {/* Select Agents Button */}
            {!selectMode && (
                <button className="select-agents-button" onClick={() => setSelectMode(true)}>
                    Select Agents
                </button>
            )}

            {/* Display Send and Cancel buttons in selection mode */}
            {selectMode && (
                <div className="selection-actions">
                    <button className="send-to-agents-button" onClick={handleSendToAgents} disabled={selectedAgents.length === 0}>
                        Send to Agents
                    </button>
                    <button className="cancel-selection-button" onClick={handleCancelSelection}>
                        Cancel
                    </button>
                </div>
            )}

            <table className="agents-table">
                <thead>
                    <tr>
                        {selectMode && <th>Select</th>} {/* Show checkbox column in selection mode */}
                        <th>Agent Company</th>
                        <th>Agent Name</th>
                        <th>Agent Email</th>
                        <th>Agent Contact</th>
                        <th>City</th>
                        <th>Country</th>
                    </tr>
                </thead>
                <tbody>
                    {agents.map((agent) => (
                        <tr key={agent.id}>
                            {selectMode && (
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={selectedAgents.includes(agent.id)}
                                        onChange={() => handleSelectAgent(agent.id)}
                                    />
                                </td>
                            )}
                            <td>{agent.agent_company}</td>
                            <td>{agent.agent_name}</td>
                            <td>{agent.agent_email}</td>
                            <td>{agent.agent_contact}</td>
                            <td>{agent.city}</td>
                            <td>{agent.country}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default AgentDetails;
