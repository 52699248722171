import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'; // New icon for sign-out
import { auth } from '../firebase';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import './Profile.css';

const Profile = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState(null);
    const dropdownRef = useRef(null); // Use a ref to reference the dropdown

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleLogout = () => {
        auth.signOut();
        console.log("Logging out...");
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                setUser(user);
                const userRef = doc(db, 'users', user.uid);
                const userSnap = await getDoc(userRef);

                if (userSnap.exists()) {
                    setUserData(userSnap.data());
                } else {
                    console.log("No such user document in Firestore!");
                }
            } else {
                setUser(null);
                setUserData(null);
            }
        });

        return () => unsubscribe();
    }, []);

    // Close the dropdown if clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    return (
        <div className="header">
            <div className="profile-container">
                <div className="profile-avatar" onClick={toggleDropdown}>
                    {userData?.avatar ? (
                        <img src={userData.avatar} alt="User Avatar" className="avatar-image" />
                    ) : (
                        <div className="default-avatar">
                            {userData?.user_name?.[0].toUpperCase() || 'U'}
                        </div>
                    )}
                </div>
                {isOpen && (
                    <div className="profile-dropdown" ref={dropdownRef}>
                        {user && userData ? (
                            <div className="profile-details">
                                <p><strong>Username:</strong> {userData.user_name}</p>
                                <p><strong>Email:</strong> {userData.email}</p>
                                <p><strong>Role:</strong> {userData.role}</p>
                            </div>
                        ) : (
                            <p>Please log in to view your profile.</p>
                        )}
                        <button className="logout-button" onClick={handleLogout}>
                            <FontAwesomeIcon icon={faSignOutAlt} /> Logout
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Profile;
