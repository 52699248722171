import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useParams } from 'react-router-dom';
import RequestDetails from './QuoteRequestDetails';
import RequestQuote from './RequestQuote';
import Quotations from './Quotations.js';
import "./QuoteDetails.css"
const QuoteDetails = () => {
    const { quoteId } = useParams();
    const [quoteDetails, setQuoteDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('RequestDetails');

    // Fetch quote details from Firestore using quoteId
    useEffect(() => {
        const fetchQuoteDetails = async () => {
            try {
                const quoteDocRef = doc(db, 'QuoteRequests', quoteId);
                const quoteDoc = await getDoc(quoteDocRef);

                if (quoteDoc.exists()) {
                    setQuoteDetails({ id: quoteDoc.id, ...quoteDoc.data() });
                } else {
                    console.error('No such document!');
                }
            } catch (error) {
                console.error('Error fetching quote details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchQuoteDetails();
    }, [quoteId]);

    if (loading) {
        return <div className="loading">Loading...</div>;
    }

    if (!quoteDetails) {
        return <div className="error">No quote details found for this request.</div>;
    }

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    return (
        <div className="quote-details-view">
            <h2>Quote Details</h2>

            <div className="tabs">
                <button
                    className={`tab ${activeTab === 'RequestDetails' ? 'active' : ''}`}
                    onClick={() => handleTabClick('RequestDetails')}
                >
                    Request Details
                </button>
                <button
                    className={`tab ${activeTab === 'RequestQuote' ? 'active' : ''}`}
                    onClick={() => handleTabClick('RequestQuote')}
                >
                    Request Quote
                </button>
                <button
                    className={`tab ${activeTab === 'Quotations' ? 'active' : ''}`}
                    onClick={() => handleTabClick('Quotations')}
                >
                    Quotations
                </button>
            </div>

            <div className="tab-content">
                {activeTab === 'RequestDetails' && (
                    <RequestDetails quoteDetails={quoteDetails} />
                )}
                {activeTab === 'RequestQuote' && (
                    <RequestQuote quoteDetails={quoteDetails} />
                )}
                {activeTab === 'Quotations' && (
                    <Quotations quoteId={quoteId} />
                )}
            </div>
        </div>
    );
};

export default QuoteDetails;
