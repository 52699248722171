import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useParams } from 'react-router-dom';
import './QuoteRequestDetails.css';

const RequestDetails = () => {
    const { quoteId } = useParams();
    const [quoteDetails, setQuoteDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [emailBody, setEmailBody] = useState('');

    useEffect(() => {
        const fetchQuoteDetails = async () => {
            try {
                const quoteDocRef = doc(db, 'QuoteRequests', quoteId);
                const quoteDoc = await getDoc(quoteDocRef);

                if (quoteDoc.exists()) {
                    const data = quoteDoc.data();
                    setQuoteDetails({ id: quoteDoc.id, ...data });
                    setEmailBody(data.emailBody || ''); // Set email body for editing
                } else {
                    console.error('No such document!');
                }
            } catch (error) {
                console.error('Error fetching quote details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchQuoteDetails();
    }, [quoteId]);

    const saveUpdatedEmail = async () => {
        try {
            const quoteDocRef = doc(db, 'QuoteRequests', quoteId);
            await updateDoc(quoteDocRef, {
                emailBody: emailBody, // Update emailBody field in Firestore
            });
            alert('Email updated successfully!');
        } catch (error) {
            console.error('Error updating email:', error);
        }
    };

    if (loading) {
        return <div className="loading">Loading...</div>;
    }

    if (!quoteDetails) {
        return <div>No quote details found for this request.</div>;
    }

    return (
        <div className="quote-details-view">
            <h2>Quote Request Details</h2>
            <div className="quote-card">
                <p><strong>Quote Request ID:</strong> {quoteDetails.id}</p>
                <p><strong>Request ID:</strong> {quoteDetails.request_id}</p>
                <p><strong>Origin:</strong> {quoteDetails.origin}</p>
                <p><strong>Destination:</strong> {quoteDetails.destination}</p>
                <p><strong>Special Instructions:</strong> {quoteDetails.specialInstructions}</p>

                {/* Editable Email Field */}
                <div className="email-edit">
                    <label htmlFor="email">Email Body:</label>
                    <textarea
                        id="email"
                        value={emailBody}
                        onChange={(e) => setEmailBody(e.target.value)} // Update emailBody state on change
                        rows="12"
                    />
                </div>

                <button className="save-button" onClick={saveUpdatedEmail}>Save</button>
            </div>
        </div>
    );
};

export default RequestDetails;
