import React from 'react';

const Filters = ({ filter, onFilterChange }) => {
  const handleQueryIdChange = (e) => {
    onFilterChange({ ...filter, queryId: e.target.value });
  };

  const handleStatusChange = (e) => {
    onFilterChange({ ...filter, status: e.target.value });
  };

  return (
    <div className="filters">
      <input
        type="text"
        placeholder="Filter by Query ID"
        value={filter.queryId}
        onChange={handleQueryIdChange}
      />
      <select value={filter.status} onChange={handleStatusChange}>
        <option value="">All Status</option>
        <option value="Active">Active</option>
        <option value="Pending">Pending</option>
        <option value="Completed">Completed</option>
      </select>
    </div>
  );
};

export default Filters; // Default export
