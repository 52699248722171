import React, { useState, useEffect } from 'react';
import './QueryParameters.css'; // Create a CSS file for styling if necessary

const QueryParameters = ({ query }) => {
    // Convert the query_parameters object to an array of key-value pairs
    const initialFields = Object.entries(query).map(([name, value]) => {
        // Convert the value to a JSON string if it's an object
        const displayValue = typeof value === 'object' ? JSON.stringify(value) : value;
        return { name, value: displayValue };
    });
    
    const [fields, setFields] = useState(initialFields);

    useEffect(() => {
        // Update fields when query prop changes
        const updatedFields = Object.entries(query).map(([name, value]) => {
            const displayValue = typeof value === 'object' ? JSON.stringify(value) : value;
            return { name, value: displayValue };
        });
        setFields(updatedFields);
    }, [query]);

    const addParameter = () => {
        // Add a new parameter (empty initially)
        setFields([...fields, { name: '', value: '' }]);
    };

    const updateField = (index, fieldName, value) => {
        const updatedFields = fields.map((field, i) =>
            i === index ? { ...field, [fieldName]: value } : field
        );
        setFields(updatedFields);
    };

    const deleteField = (index) => {
        const updatedFields = fields.filter((_, i) => i !== index);
        setFields(updatedFields);
    };

    return (
        <div className="query-parameters-view">
            {fields.map((field, index) => (
                <div key={index} className="field-row">
                    <input
                        type="text"
                        value={field.name}
                        onChange={(e) => updateField(index, 'name', e.target.value)}
                        placeholder="Field Name"
                        className="field-input"
                    />
                    <input
                        type="text"
                        value={field.value}
                        onChange={(e) => updateField(index, 'value', e.target.value)}
                        placeholder="Field Value"
                        className="field-input"
                    />
                    <button onClick={() => deleteField(index)} className="delete-button">Delete</button>
                </div>
            ))}
            <button onClick={addParameter} className="add-button">+ Add Parameter</button>
        </div>
    );
};

export default QueryParameters;
