import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase'; // Ensure Firestore is imported
import { doc, getDoc } from 'firebase/firestore'; // Import Firestore methods
import './ViewQuery.css';
import QueryParameters from './QueryParameters'; // Import QueryParameters component
import Request from './Request'; // Import Requests component (corrected import name)
import FinalQuote from './FinalQuote'; // Import FinalQuote component

const ViewQuery = () => {
    const { id } = useParams(); // Get the query ID from the URL
    const [query, setQuery] = useState(null);
    const [activeTab, setActiveTab] = useState('queryParameters');
    const [loading, setLoading] = useState(true);

    // Fetch query data from Firestore
    useEffect(() => {
        const fetchQuery = async () => {
            try {
                const queryDoc = doc(db, 'Queries', id); // Get document by ID
                const docSnapshot = await getDoc(queryDoc);

                if (docSnapshot.exists()) {
                    const queryData = docSnapshot.data();
                    setQuery(queryData);
                } else {
                    setQuery({ error: 'Query not found' });
                }
            } catch (error) {
                console.error('Error fetching query:', error);
            } finally {
                setLoading(false); // Set loading to false when data is fetched
            }
        };

        fetchQuery();
    }, [id]); // Dependency array to refetch when id changes

    if (loading) return <div>Loading...</div>;
    if (!query) return <div>Query not found</div>;

    return (
        <div className="view-query-container">
            <h1>Query ID: {id}</h1>

            {/* Tabs for different sections */}
            <div className="tab-buttons">
                <button
                    onClick={() => setActiveTab('queryParameters')}
                    className={`tab-button ${activeTab === 'queryParameters' ? 'active' : ''}`}
                >
                    Query Parameters
                </button>
                <button
                    onClick={() => setActiveTab('requests')}
                    className={`tab-button ${activeTab === 'requests' ? 'active' : ''}`}
                >
                    Requests
                </button>
                <button
                    onClick={() => setActiveTab('finalQuote')}
                    className={`tab-button ${activeTab === 'finalQuote' ? 'active' : ''}`}
                >
                    Final Quote
                </button>
            </div>

            {/* Render views based on the active tab */}
            {activeTab === 'queryParameters' && query?.query_parameters && (
                <QueryParameters query={query.query_parameters} />
            )}
            {/* Pass the queryId to the Request component */}
            {activeTab === 'requests' && <Request query={{ queryId: id }} />} {/* Pass queryId here */}
            {activeTab === 'finalQuote' && <FinalQuote query={{ queryId: id }} />} {/* Pass queryId correctly */}
        </div>
    );
};

export default ViewQuery;
