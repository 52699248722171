// src/components/PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { auth } from '../firebase'; // Adjust the path if needed

const PrivateRoute = ({ children }) => {
  const isAuthenticated = !!auth.currentUser; // Check if the user is authenticated

  return isAuthenticated ? children : <Navigate to="/login" replace />; // Redirect if not authenticated
};

export default PrivateRoute;
