import React, { useEffect, useState } from 'react';
import { db } from '../firebase'; // Firestore reference
import { doc, getDoc } from 'firebase/firestore';
import './QuotationDetails.css'; // Create a CSS file for styling if necessary

const QuotationDetails = ({ quoteId, agentId }) => {
    const [quotationParameters, setQuotationParameters] = useState(null);
    const [loading, setLoading] = useState(true);
    const [fields, setFields] = useState([]);

    useEffect(() => {
        const fetchQuotationDetails = async () => {
            try {
                console.log(quoteId,agentId)
                const quoteRequestRef = doc(db, 'QuoteRequests', quoteId);
                const quoteRequestSnap = await getDoc(quoteRequestRef);

                if (quoteRequestSnap.exists()) {
                    const data = quoteRequestSnap.data();
                    const parameters = data.Agent_Quotations?.[agentId]?.quotation_parameters || {};

                    setQuotationParameters(parameters);
                    setFields(Object.entries(parameters).map(([name, value]) => ({ name, value })));
                } else {
                    console.log('No such document!');
                }
            } catch (error) {
                console.error('Error fetching quotation details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchQuotationDetails();
    }, [quoteId, agentId]);

    const updateField = (index, fieldName, value) => {
        const updatedFields = fields.map((field, i) =>
            i === index ? { ...field, [fieldName]: value } : field
        );
        setFields(updatedFields);
    };

    const deleteField = (index) => {
        const updatedFields = fields.filter((_, i) => i !== index);
        setFields(updatedFields);
    };

    const addParameter = () => {
        setFields([...fields, { name: '', value: '' }]);
    };

    if (loading) return <div>Loading...</div>;
    if (!quotationParameters) return <div>No quotation details found.</div>;

    return (
        <div className="quotation-details-view">
            {fields.map((field, index) => (
                <div key={index} className="field-row">
                    <input
                        type="text"
                        value={field.name}
                        onChange={(e) => updateField(index, 'name', e.target.value)}
                        placeholder="Field Name"
                        className="field-input"
                    />
                    <input
                        type="text"
                        value={field.value}
                        onChange={(e) => updateField(index, 'value', e.target.value)}
                        placeholder="Field Value"
                        className="field-input"
                    />
                    <button onClick={() => deleteField(index)} className="delete-button">Delete</button>
                </div>
            ))}
            <button onClick={addParameter} className="add-button">+ Add Parameter</button>
        </div>
    );
};

export default QuotationDetails;
