import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import ActiveQueries from './pages/ActiveQueries';
import WcaRequests from './pages/WcaRequests';  // For International Operations requests page
import SalesRequests from './pages/SalesRequests'; // For Sales requests page
import ViewQueries from './pages/ViewQuery';
import Profile from './components/Profile';
import Login from './pages/auth/Login';
import PrivateRoute from './components/PrivateRoute';
import RequestDetails from './pages/RequestDetails';
import GetQuote from './pages/GetQuote';  // Importing GetQuote component
import QuoteDetails from './pages/QuoteDetails'; // Importing QuoteDetails component
import { auth, db } from './firebase';  // Firebase setup
import { doc, getDoc } from 'firebase/firestore';  // Firestore query methods
import QuotationView from './pages/QuotationView.js';
import './App.css';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);  // To store user's role
  const [loading, setLoading] = useState(true);    // Loading state to handle async fetching of user role

  // Listen for authentication changes and fetch role based on user UID (doc ID)
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setIsAuthenticated(true);

        try {
          // Get the user document from Firestore using the user's UID (doc ID)
          const userDocRef = doc(db, 'users', user.uid);  // Use user.uid as the document ID
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            console.log('User role fetched:', userData.role);  // Log the fetched role
            setUserRole(userData.role);  // Only set the user role
          } else {
            console.error('No such user document!');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        setIsAuthenticated(false);
      }
      setLoading(false);  // Set loading to false after role is fetched
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;  // Show a loading state while fetching the user data
  }

  // Determine default page based on user role
  const getDefaultPage = () => {
    if (!userRole) {
      console.log('userRole is null or undefined:', userRole);  // Log when userRole is null or undefined
      return <Navigate to="/unauthorized" />;  // If no role or unknown role, show unauthorized
    }
    switch (userRole) {
      case 'sales':
        return <ActiveQueries />;  // Sales role will see ActiveQueries by default
      case 'International Operations':
        case 'Pricing':
        return <WcaRequests />;  // For International Operations (WCA role), show WcaRequests
      default:
        return <Navigate to="/unauthorized" />;  // If no role or unknown role, show unauthorized
    }
  };

  console.log('Current userRole:', userRole);  // Log the current value of userRole before rendering

  return (
    <Router>
      <div className="app-container">
        {isAuthenticated && userRole && <Sidebar userRole={userRole} />}  {/* Sidebar with role-based content */}
        <div className="content">
          <div className="header">
            {isAuthenticated && <Profile />} {/* Show user profile when authenticated */}
          </div>
          <Routes>
            <Route path="/login" element={<Login />} />
            
            <Route 
              path="/" 
              element={isAuthenticated ? (
                <PrivateRoute>
                  {userRole ? getDefaultPage() : <div>Loading role...</div>}  {/* Render only if userRole is set */}
                </PrivateRoute>
              ) : (
                <Navigate to="/login" />
              )}
            />

            {/* Other routes */}
            <Route 
              path="/view-queries/:id" 
              element={ 
                <PrivateRoute>
                  <ViewQueries />
                </PrivateRoute>
              } 
            />
            <Route
              path="/request/:requestId"
              element={
                <PrivateRoute>
                  <RequestDetails />
                </PrivateRoute>
              }
              
            />
            <Route path="/quotation-view/:quoteId/:agentId" element={<QuotationView />} />
            <Route 
              path="/sales-requests" 
              element={
                <PrivateRoute>
                  <SalesRequests />
                </PrivateRoute>
              } 
            />
           
            {/* Adding the Get Quote route */}
            <Route 
              path="/get-quote"  
              element={
                <PrivateRoute>
                  <GetQuote />
                </PrivateRoute>
              }
            />

            {/* Adding the new QuoteDetails route */}
            <Route 
              path="/quote-details/:quoteId"  // Dynamic path for QuoteDetails page
              element={
                <PrivateRoute>
                  <QuoteDetails />
                </PrivateRoute>
              } 
            />

            <Route path="/unauthorized" element={<div>You are not authorized to view this page.</div>} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
