import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import './QuotationView.css';
import QuotationDetails from './QuotationDetails';
import ChatWithAgent from './ChatWithAgent';

const QuotationView = () => {
    const { quoteId, agentId } = useParams(); // Capture both quoteId and agentId
    const location = useLocation(); // Use location to access passed state
    const [quotation] = useState(location.state?.quotation || null); // Get quotation from state
    const [activeTab, setActiveTab] = useState('quotationDetails');

    if (!quotation) return <div>Quotation not found</div>;

    return (
        <div className="quotations-view-container">
            <h1>Quotation ID: {quoteId} | Agent ID: {agentId}</h1>

            {/* Tabs for different sections */}
            <div className="tab-buttons">
                <button
                    onClick={() => setActiveTab('quotationDetails')}
                    className={`tab-button ${activeTab === 'quotationDetails' ? 'active' : ''}`}
                >
                    Quotation Details
                </button>
                <button
                    onClick={() => setActiveTab('chatWithAgent')}
                    className={`tab-button ${activeTab === 'chatWithAgent' ? 'active' : ''}`}
                >
                    Chat With Agent
                </button>
            </div>

            {/* Render views based on the active tab */}
            {activeTab === 'quotationDetails' && quotation && (
                <QuotationDetails quoteId={quoteId} agentId={agentId} />
            )}
            {activeTab === 'chatWithAgent' && (
                <ChatWithAgent quoteId={quoteId} agentId={agentId} />
            )}
        </div>
    );
};

export default QuotationView;
