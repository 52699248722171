// Quotations.js (React Component)
import React, { useState, useEffect } from 'react';
import { db } from '../firebase'; // Firestore reference
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './Quotations.css'; // CSS file for styling

const Quotations = ({ quoteId }) => {
    const navigate = useNavigate(); 
    const [agentQuotations, setAgentQuotations] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAgentQuotations = async () => {
            try {
                const quoteRequestRef = doc(db, 'QuoteRequests', quoteId);
                const quoteRequestSnap = await getDoc(quoteRequestRef);
                console.log(quoteId)
                if (quoteRequestSnap.exists()) {
                    const data = quoteRequestSnap.data();
                    const quotations = data.Agent_Quotations || [];
                    setAgentQuotations(quotations);
                    //console.log("quotations are",agentQuotations)
                } else {
                    console.log('No such document!');
                }
            } catch (error) {
                console.error('Error fetching quotations:', error);
            } finally {
                setLoading(false);
            }
        };

        if (quoteId) {
            fetchAgentQuotations();
        }
    }, [quoteId]);

    if (loading) {
        return <div className="loading">Loading quotations...</div>;
    }

    if (agentQuotations.length === 0) {
        return <div>No quotations found for this request {quoteId}.</div>;
    }

    const handleViewQuote = (agentId, quotation) => {
        console.log(agentId)
        navigate(`/quotation-view/${quoteId}/${agentId}`, {
            state: { quotation, quoteId, agentId }
        });
    };

    return (
        <table className="query-table">
            <thead>
                <tr>

        <th style={{ backgroundColor: '#3498db', color: 'white' }}>Agent Company</th>
        <th style={{ backgroundColor: '#3498db', color: 'white' }}>Total Cost</th>
        <th style={{ backgroundColor: '#3498db', color: 'white' }}>Shipping Date</th>
        <th style={{ backgroundColor: '#3498db', color: 'white' }}>Arrival</th>
        <th style={{ backgroundColor: '#3498db', color: 'white' }}>Actions</th>

   
                </tr>
            </thead>
            <tbody>
                {Object.entries(agentQuotations).map(([agentId, quotation]) => (
                    <tr key={agentId}>
                        
                        <td>{quotation.agent_company ?? 'N/A'}</td>
<td>${quotation.quotation_parameters?.total_cost ?? quotation.quotation_parameters?.Total_Cost ?? "N/A"}</td>
<td>{quotation.quotation_parameters?.Shipping_Date ?? 'N/A'}</td>
<td>{quotation.quotation_parameters?.Arrival_Date ?? 'N/A'}</td>

                        <td>
                            <button 
                                onClick={() => handleViewQuote(agentId, quotation)}
                                className="view-quote-button"
                            >
                                View
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default Quotations;
