import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebase'; // Firestore and Auth
import { collection, query, where, getDocs, addDoc } from 'firebase/firestore'; // Import addDoc to add new docs
import Filters from '../components/Filters'; 
import Table from '../components/Table'; 
import NewQueryForm from '../components/NewQueryForm'; 
import './ActiveQueries.css';

const ActiveQueries = () => {
    const [filter, setFilter] = useState({ queryId: '', status: '' });
    const [showForm, setShowForm] = useState(false); // State to control the visibility of the form
    const [data, setData] = useState([]); // State to hold query data
    const [userCompany, setUserCompany] = useState(null); // State to store user company
    const [loading, setLoading] = useState(true); // State for loading indicator

    const handleFilterChange = (newFilter) => {
        setFilter(newFilter);
    };

    const getFormattedDate = () => {
        const today = new Date();
        
        // Convert to IST by adjusting the timezone offset (IST is UTC+5:30)
        const offset = 5.5 * 60; // offset in minutes
        const istDate = new Date(today.getTime() + offset * 60 * 1000);
    
        // Extract date components
        const day = String(istDate.getUTCDate()).padStart(2, '0');
        const month = String(istDate.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = istDate.getUTCFullYear();
    
        // Return the formatted date string as dd-mm-yyyy
        return `${day}-${month}-${year}`;
    };
    // Fetch data from Firestore
    useEffect(() => {
        const fetchQueries = async () => {
            try {
                const currentUser = auth.currentUser;
                
                if (!currentUser) {
                    console.log("No user logged in");
                    return;
                }

                console.log("Current user:", currentUser.email);

                // Fetch user data based on email
                const userDocRef = collection(db, 'users');
                const userSnapshot = await getDocs(query(userDocRef, where('email', '==', currentUser.email)));
                
                if (!userSnapshot.empty) {
                    const userData = userSnapshot.docs[0].data();
                    console.log("User Data:", userData);
                    setUserCompany(userData.company); // Set user company
                } else {
                    console.log("User document not found");
                    return;
                }

                if (userCompany) {
                    const queriesRef = collection(db, 'Queries');
                    const q = query(queriesRef, where('company', '==', userCompany));
                    const querySnapshot = await getDocs(q);

                    const fetchedData = querySnapshot.docs.map(doc => {
                        const queryData = doc.data();
                        const queryParameters = queryData.query_parameters || {};

                        const origin = queryData.Origin|| queryData.origin||queryParameters.Origin || queryParameters.origin ||queryParameters.POL ||   'Unknown Origin';
                        const destination = queryData.Destination||queryData.destination|| queryParameters.Destination || queryParameters.destination||queryParameters.POD || 'Unknown Destination';
                        const Usr_comp=queryParameters.company;
                        const customerName=queryParameters.Customer_Name||queryData.customer_name||"Unknown Name"
                        const customerCompany=queryParameters.Customer_Company||queryData.customer_company||"Unknown Name"

                        return {
                            customerName:customerName, 
                            customerCompany:customerCompany, 
                            queryId: doc.id,
                            company: Usr_comp,
                            status: queryData.status ||"NA",
                            date: queryData.date,
                            origin: origin,
                            destination: destination
                        };
                    });

                    setData(fetchedData);
                }
            } catch (error) {
                console.error("Error fetching queries: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchQueries();
    }, [userCompany]);

    const handleCreateQuery = async (newQuery) => {
        try {
            // Add the company to the query
            const currentUser = auth.currentUser;
            if (!currentUser) return;

            const userSnapshot = await getDocs(query(collection(db, 'users'), where('email', '==', currentUser.email)));
            if (!userSnapshot.empty) {
                const userData = userSnapshot.docs[0].data();
                newQuery.company = userData.company;
                newQuery.date=getFormattedDate();
                newQuery.status="Pending" 

                // Save the query to Firestore
                await addDoc(collection(db, 'Queries'), newQuery);
                console.log("Query successfully added to Firestore!");

                // Close the form after successful submission
                setShowForm(false);
            }
        } catch (error) {
            console.error("Error adding query to Firestore: ", error);
        }
    };

    if (loading) {
        return <div>Loading queries...</div>;
    }

    return (
        <div className="active-queries">
            <h1>Active Queries</h1>

            <div className="header-container">
                <Filters filter={filter} onFilterChange={handleFilterChange} />

                <div className="create-query-button-container">
                    <button className="create-query-button" onClick={() => setShowForm(true)}>+ Create New Query</button>
                </div>
            </div>

            {showForm && (
                <NewQueryForm 
                    onCreateQuery={handleCreateQuery}  // Pass handleCreateQuery here
                    onClose={() => setShowForm(false)}
                />
            )}

            <Table data={data.filter(query => query.queryId.includes(filter.queryId) && query.status.includes(filter.status))} />
        </div>
    );
};

export default ActiveQueries;
