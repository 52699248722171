import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc, collection, query, where, getDocs, addDoc, orderBy, serverTimestamp } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import './RequestDetails.css'; // Optional CSS file for styling

const RequestDetails = () => {
    const { requestId } = useParams(); // Get the request ID from the URL
    const [request, setRequest] = useState(null); // State to hold request details
    const [comments, setComments] = useState([]); // State to hold comments
    const [newComment, setNewComment] = useState(''); // State for new comment input
    const [loading, setLoading] = useState(true); // Loading state for the request
    const [submitting, setSubmitting] = useState(false); // Submitting state for comments
    const auth = getAuth();

    // Fetch request details from Firestore
    useEffect(() => {
        const fetchRequest = async () => {
            try {
                const requestDoc = doc(db, 'Requests', requestId);
                const docSnapshot = await getDoc(requestDoc);

                if (docSnapshot.exists()) {
                    setRequest(docSnapshot.data());
                } else {
                    console.error('Request not found');
                }
            } catch (error) {
                console.error('Error fetching request details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchRequest();
    }, [requestId]);

    // Fetch comments related to the current request
    useEffect(() => {
        const fetchComments = async () => {
            const commentsQuery = query(
                collection(db, 'Comments'),
                where('request_id', '==', requestId),
                //orderBy('timestamp', 'asc') // Order comments by timestamp
            );

            const commentSnapshot = await getDocs(commentsQuery);
            const fetchedComments = commentSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));

            // Log the fetched comments to see their structure
            console.log(fetchedComments); // Log to check the format of comments
            setComments(fetchedComments);
        };

        fetchComments();
    }, [requestId]);

    // Handle new comment submission
    const handleCommentSubmit = async () => {
        if (newComment.trim() === '') {
            alert('Comment cannot be empty.');
            return;
        }

        const currentUser = auth.currentUser;
        if (!currentUser) {
            alert('You need to be logged in to submit a comment.');
            return;
        }

        setSubmitting(true);

        try {
            const commentData = {
                request_id: requestId,
                user: currentUser.email, // Use email or any other user identifier
                comment: newComment,
                timestamp: serverTimestamp(), // Correctly using Firestore's server timestamp
            };

            // Add new comment to Firestore
            const docRef = await addDoc(collection(db, 'Comments'), commentData);

            // Update local state to show the new comment immediately
            setComments(prevComments => [
                ...prevComments,
                { id: docRef.id, ...commentData, timestamp: new Date() } // Append new comment locally
            ]);
            setNewComment(''); // Clear input field
        } catch (error) {
            console.error('Error submitting comment:', error);
        } finally {
            setSubmitting(false);
        }
    };

    if (loading) return <div>Loading...</div>;
    if (!request) return <div>Request not found</div>;

    return (
        <div className="request-details-container">
            <h1>Request ID: {requestId}</h1>
            <div className="request-info">
                <p><strong>Department:</strong> {request.department}</p>
                <p><strong>Status:</strong> {request.status}</p>
                <p><strong>Details:</strong> {request.request_details}</p>
                <p><strong>Raised Date:</strong> {new Date(request.date_created).toLocaleDateString()}</p>
            </div>

            <h2>Comments</h2>
            <div className="comments-section">
                {comments.length > 0 ? (
                    comments.map(comment => (
                        <div key={comment.id} className="comment">
                            <p><strong>{comment.user}:</strong> {comment.comment}</p>
                            <p className="comment-timestamp">
                                {comment.timestamp instanceof Object && comment.timestamp.toDate 
                                    ? comment.timestamp.toDate().toLocaleString() 
                                    : 'Just now'}
                            </p>
                        </div>
                    ))
                ) : (
                    <p>No comments yet. Be the first to comment!</p>
                )}
            </div>

            <div className="comment-input">
                <textarea
                    placeholder="Write your comment..."
                    value={newComment}
                    onChange={e => setNewComment(e.target.value)}
                    disabled={submitting}
                />
                <button onClick={handleCommentSubmit} disabled={submitting}>
                    {submitting ? 'Submitting...' : 'Submit Comment'}
                </button>
            </div>
        </div>
    );
};

export default RequestDetails;
