import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './Sidebar.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Sidebar = ({ userRole }) => {
  const location = useLocation(); // Get the current URL

  // Helper function to determine if a route is part of a section
  const isActiveSection = (basePath) => {
    return location.pathname.startsWith(basePath) && basePath !== '/';
  };

  return (
    <div className="sidebar">
      <h2 className="sidebar-title">
        FreightPilot<span>AI</span>
      </h2>
      <ul>
        {/* Sales-specific sidebar items */}
        {userRole === 'sales' && (
          <>
            <li>
              <NavLink
                to="/"
                className={location.pathname === '/' ? 'active' : ''}
                end // Ensures exact match for root '/'
              >
                <i className="fas fa-tasks"></i> Queries
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/sales-requests"
                className={isActiveSection('/sales-requests') ? 'active' : ''}
              >
                <i className="fas fa-paper-plane"></i> Requests
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/customers-database"
                className={isActiveSection('/customers-database') ? 'active' : ''}
              >
                <i className="fas fa-users"></i> Customers Database
              </NavLink>
            </li>
          </>
        )}

        {/* WCA-specific sidebar items */}
        {userRole === 'International Operations' && (
          <>
            <li>
              <NavLink
                to="/"
                className={location.pathname === '/' ? 'active' : ''}
                end // Ensures exact match for root '/'
              >
                <i className="fas fa-paper-plane"></i> Requests
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/wca-reports"
                className={isActiveSection('/wca-reports') ? 'active' : ''}
              >
                <i className="fas fa-globe"></i> International Queries
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/get-quote"
                className={isActiveSection('/get-quote') ? 'active' : ''}
              >
                <i className="fas fa-quote-left"></i> Get Quote
              </NavLink>
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default Sidebar;
