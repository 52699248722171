import React, { useState } from 'react';
import './NewQueryForm.css';

const NewQueryForm = ({ onCreateQuery, onClose }) => {
    const [customerName, setCustomerName] = useState('');
    const [company, setCompany] = useState('');
    const [origin, setOrigin] = useState('');
    const [destination, setDestination] = useState('');
    const [shipmentType, setShipmentType] = useState('FCL');
    const [containerType, setContainerType] = useState('');
    const [numberOfContainers, setNumberOfContainers] = useState('');
    const [weight, setWeight] = useState('');
    const [dimensions, setDimensions] = useState('');
    const [numberOfPackages, setNumberOfPackages] = useState('');
    const [parameters, setParameters] = useState([]); // Dynamic parameters
    const [leadSource, setLeadSource] = useState('Online'); // State for lead source

    const handleSubmit = (e) => {
        e.preventDefault();

        // Structure the data for Firestore
        const queryData = {
            customer_name: customerName,
            customer_company: company,
            origin: origin,
            destination: destination,
            query_parameters: {
                shipment_type: shipmentType,
                ...(shipmentType === 'FCL' && { container_type: containerType, number_of_containers: numberOfContainers }),
                ...(shipmentType === 'AIR' && { weight, dimensions, number_of_packages: numberOfPackages }),
                // Add additional dynamic parameters to query_parameters
                ...parameters.reduce((acc, param) => {
                    acc[param.name] = param.value;
                    return acc;
                }, {}),
                lead_source: leadSource // Include the lead source in the query parameters
            }
        };

        // Pass the formatted data to the parent component to handle Firestore logic
        onCreateQuery(queryData);
    };

    const addParameter = () => {
        setParameters([...parameters, { name: '', value: '' }]);
    };

    const updateParameter = (index, field, value) => {
        const updatedParams = parameters.map((param, i) =>
            i === index ? { ...param, [field]: value } : param
        );
        setParameters(updatedParams);
    };

    const deleteParameter = (index) => {
        const updatedParams = parameters.filter((_, i) => i !== index);
        setParameters(updatedParams);
    };

    return (
        <div className="new-query-form">
            <h2>Create New Query</h2>
            <form onSubmit={handleSubmit}>
                <input type="text" placeholder="Customer Name" value={customerName} onChange={(e) => setCustomerName(e.target.value)} required />
                <input type="text" placeholder="Company" value={company} onChange={(e) => setCompany(e.target.value)} required />
                <input type="text" placeholder="Origin" value={origin} onChange={(e) => setOrigin(e.target.value)} required />
                <input type="text" placeholder="Destination" value={destination} onChange={(e) => setDestination(e.target.value)} required />

                <select value={shipmentType} onChange={(e) => setShipmentType(e.target.value)}>
                    <option value="FCL">FCL</option>
                    <option value="LCL">LCL</option>
                    <option value="AIR">Air</option>
                </select>

                {shipmentType === 'FCL' && (
                    <>
                        <input type="text" placeholder="Container Type" value={containerType} onChange={(e) => setContainerType(e.target.value)} required />
                        <input type="number" placeholder="Number of Containers" value={numberOfContainers} onChange={(e) => setNumberOfContainers(e.target.value)} required />
                    </>
                )}

                {shipmentType === 'AIR' && (
                    <>
                        <input type="number" placeholder="Weight" value={weight} onChange={(e) => setWeight(e.target.value)} required />
                        <input type="text" placeholder="Dimensions" value={dimensions} onChange={(e) => setDimensions(e.target.value)} required />
                        <input type="number" placeholder="Number of Packages" value={numberOfPackages} onChange={(e) => setNumberOfPackages(e.target.value)} required />
                    </>
                )}

                <select value={leadSource} onChange={(e) => setLeadSource(e.target.value)}>
                    <option value="Online">Online</option>
                    <option value="Email">Email</option>
                    <option value="Cold Calling">Cold Calling</option>
                    <option value="Whatsapp">Whatsapp</option>
                </select>

                <h3>Additional Parameters</h3>
                {parameters.map((param, index) => (
                    <div key={index} className="parameter-row">
                        <input
                            type="text"
                            placeholder="Field Name"
                            value={param.name}
                            onChange={(e) => updateParameter(index, 'name', e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="Field Value"
                            value={param.value}
                            onChange={(e) => updateParameter(index, 'value', e.target.value)}
                        />
                        <button type="button" onClick={() => deleteParameter(index)} className="delete-button">Delete</button>
                    </div>
                ))}
                <button type="button" className="add-button" onClick={addParameter}>+ Add Parameter</button>

                <button type="submit">Create Query</button>
                <button
                    type="button"
                    onClick={(e) => {
                        e.preventDefault(); // Prevent any default form action
                        onClose(); // Call the onClose function
                    }}
                >
                    Cancel
                </button>
            </form>
        </div>
    );
};

export default NewQueryForm;
