import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { Link } from 'react-router-dom';
import './Request.css';

const SalesRequests = () => {
    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userRole, setUserRole] = useState('');  // To store the user's role (i.e., department)
    const [userCompany, setUserCompany] = useState('');

    // Fetch authenticated user's role and email, and retrieve role from Firestore
    useEffect(() => {
        const fetchUserRole = async () => {
            const auth = getAuth();
            const currentUser = auth.currentUser;

            if (currentUser) {
                const userEmail = currentUser.email;

                // Query the 'users' collection using the email to find the user's role
                const usersQuery = query(collection(db, 'users'), where('email', '==', userEmail));
                const userSnapshot = await getDocs(usersQuery);

                if (!userSnapshot.empty) {
                    const userData = userSnapshot.docs[0].data();
                    setUserRole(userData.role);  // Set the role (department)
                    setUserCompany(userData.company);  // Set the user's company
                } else {
                    console.warn('User not found in Users collection');
                }
            }
        };

        fetchUserRole();
    }, []);

    // Fetch requests based on the user's role (department)
    useEffect(() => {
        const fetchRequests = async () => {
            if (userRole) {
                try {
                    // Query the 'requests' collection where department matches the user's role
                    const requestsQuery = query(
                        collection(db, 'Requests'),
                        where('company', '==', userCompany)   // Fetch requests for user's department
                    );
                    const querySnapshot = await getDocs(requestsQuery);
                    const fetchedRequests = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                    setRequests(fetchedRequests);
                } catch (error) {
                    console.error('Error fetching requests:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchRequests();
    }, [userRole]);  // Run this effect when the user's role is set

    // Render the table of requests
    const renderTable = () => {
        if (loading) {
            return <div>Loading...</div>;
        }

        if (requests.length === 0) {
            return <p>No requests are available for this department.</p>;
        }

        return (
            <table className="request-table">
                <thead>
                    <tr>
                        <th>Request ID</th> {/* New Request ID column (Document ID) */}
                        <th>Query ID</th> {/* Renamed column for query_id */}
                        <th>Department</th>
                        <th>Status</th>
                        <th>Raised Date</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {requests.map((request) => (
                        <tr key={request.id}>
                            <td>{request.id}</td> {/* Display the document ID as Request ID */}
                            <td>{request.query_id}</td> {/* Display the query_id */}
                            <td>{request.department}</td>
                            <td>{request.status}</td>
                            <td>{new Date(request.date_created).toLocaleDateString()}</td>
                            <td>
                                {/* Link to request details page */}
                                <Link to={`/request/${request.id}`}>
                                    <button className="view-button">View</button>
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    return (
        <div className="request-view">
            <h2>Requests Initiated</h2>
            {renderTable()}
        </div>
    );
};

export default SalesRequests;
