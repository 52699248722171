import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, query as firebaseQuery, where } from 'firebase/firestore'; // Import Firestore functions
import { db } from '../firebase'; // Import Firestore instance from firebase.js
import PropTypes from 'prop-types';
import './FinalQuote.css';
import { Timestamp } from 'firebase/firestore';

const FinalQuote = ({ query }) => {
    const [newQuote, setNewQuote] = useState({
        customerName: '',
        customerCompany: '',
        customerEmail: '',
        customerPhone: '',
        shipmentType: 'LCL',  // Default to LCL
        weight: '',
        numberOfPackages: '',
        dimensions: '',
        containerType: '',  // Only for FCL
        numberOfContainers: '',  // Only for FCL
        origin: '',
        destination: '',
        validUntil: '',
        totalCost: '',
        breakdownCost: [],  // List of breakdown costs
        parameters: [],  // List of additional parameters for LCL/FCL
    });

    const [quotes, setQuotes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showForm, setShowForm] = useState(false);

    // Ensure that query is passed correctly
    useEffect(() => {
        if (!query || !query.queryId) {
            console.error("Query ID is missing or invalid.");
            return;
        }

        const fetchQuotes = async () => {
            try {
                const quotesQuery = firebaseQuery(collection(db, 'Quotations'), where("queryId", "==", query.queryId));
                const querySnapshot = await getDocs(quotesQuery);
                const fetchedQuotes = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setQuotes(fetchedQuotes);
            } catch (error) {
                console.error('Error fetching quotes:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchQuotes();
    }, [query]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewQuote({ ...newQuote, [name]: value });
    };

    const handleAddBreakdownCost = () => {
        setNewQuote({
            ...newQuote,
            breakdownCost: [...newQuote.breakdownCost, { key: '', value: '' }]
        });
    };

    const handleRemoveBreakdownCost = (index) => {
        const updatedCosts = newQuote.breakdownCost.filter((_, i) => i !== index);
        setNewQuote({ ...newQuote, breakdownCost: updatedCosts });
    };

    const formatDate = (timestamp) => {
        if (!timestamp || !timestamp.toDate) return 'Invalid Date';
        const date = timestamp.toDate();  // Convert Firestore Timestamp to JS Date
        return date.toLocaleDateString();  // Format the date as desired
    };

    const handleSubmit = async () => {
        if (!newQuote.customerName || !newQuote.customerCompany || !newQuote.origin || !newQuote.destination || !newQuote.totalCost) {
            alert('Please fill in all required fields.');
            return;
        }

        if (newQuote.shipmentType === 'FCL' && (!newQuote.containerType || !newQuote.numberOfContainers)) {
            alert('Please fill in all required fields for FCL.');
            return;
        }

        if (newQuote.shipmentType !== 'FCL' && (!newQuote.weight || !newQuote.numberOfPackages || !newQuote.dimensions)) {
            alert('Please fill in all required fields for LCL/AIR.');
            return;
        }

        const quoteData = {
            ...newQuote,
            queryId: query.queryId,
            created_date: Timestamp.now(),
            breakdownCost: newQuote.breakdownCost.reduce((acc, item) => {
                acc[item.key] = item.value;
                return acc;
            }, {})
        };

        try {
            console.log("Submitting quote:", quoteData);

            const docRef = await addDoc(collection(db, 'Quotations'), quoteData);
            console.log(`Quote submitted with ID: ${docRef.id}`);

            const quotesQuery = firebaseQuery(collection(db, 'Quotations'), where("queryId", "==", query.queryId));
            const querySnapshot = await getDocs(quotesQuery);

            const updatedQuotes = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setQuotes(updatedQuotes);

            setNewQuote({
                customerName: '',
                customerCompany: '',
                customerEmail: '',
                customerPhone: '',
                shipmentType: 'LCL',
                weight: '',
                numberOfPackages: '',
                dimensions: '',
                containerType: '',
                numberOfContainers: '',
                origin: '',
                destination: '',
                validUntil: '',
                totalCost: '',
                breakdownCost: [],
                parameters: [],
            });

            setShowForm(false);
            
            const lambdaUrl = "https://cqaw2u4jr4wqp744ted2s37dy40gjarc.lambda-url.ap-south-1.on.aws/";
            const eventData = {
                documentId: docRef.id,
                ...quoteData,
            };

            const response = await fetch(lambdaUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(eventData),
            });

            if (response.ok) {
                console.log('Event successfully triggered on Lambda.');
            } else {
                console.error('Failed to trigger Lambda event:', response.statusText);
            }

        } catch (error) {
            console.error("Error submitting quote:", error);
        }
    };

    const handleViewPDF = (pdf_url) => {
        if (pdf_url) {
            window.open(pdf_url, '_blank'); // Open the PDF URL in a new tab
        } else {
            alert('PDF not available for this quote.');
        }
    };

    const renderTable = () => {
        if (loading) {
            return <div>Loading...</div>;
        }

        if (quotes.length === 0) {
            return <p>No quotes available for this query.</p>;
        }

        return (
            <table className="quote-table">
                <thead>
                    <tr>
                        <th>Quote ID</th>
                        <th>Customer Name</th>
                        <th>Company</th>
                        <th>Shipment Type</th>
                        <th>Status</th>
                        <th>Created Date</th>
                        <th>Actions</th>  {/* New column for actions */}
                    </tr>
                </thead>
                <tbody>
                    {quotes.map((quote) => (
                        <tr key={quote.id}>
                            <td>{quote.id}</td>
                            <td>{quote.customerName}</td>
                            <td>{quote.customerCompany}</td>
                            <td>{quote.shipmentType}</td>
                            <td>{quote.status || 'Pending'}</td>
                            <td>{formatDate(quote.created_date)}</td>
                            <td>
                                {/* View Button */}
                                <button onClick={() => handleViewPDF(quote.pdf_url)}>View</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    return (
        <div className="quote-view">
            <h2>Quotes for Query ID: {query.queryId}</h2>

            <div className="header-container">
                <button className="create-quote-button" onClick={() => setShowForm(true)}>+ Create Quote</button>
            </div>

            {showForm && (
                <div className="quote-form">
                    <h3>Create a New Quote</h3>
                    <label>
                        Customer Name:
                        <input
                            type="text"
                            name="customerName"
                            value={newQuote.customerName}
                            onChange={handleChange}
                            placeholder="Enter customer name"
                        />
                    </label>
                    <label>
                        Customer Company:
                        <input
                            type="text"
                            name="customerCompany"
                            value={newQuote.customerCompany}
                            onChange={handleChange}
                            placeholder="Enter customer company"
                        />
                    </label>
                    <label>
                        Customer Email:
                        <input
                            type="email"
                            name="customerEmail"
                            value={newQuote.customerEmail}
                            onChange={handleChange}
                            placeholder="Enter customer email"
                        />
                    </label>
                    <label>
                        Customer Phone:
                        <input
                            type="text"
                            name="customerPhone"
                            value={newQuote.customerPhone}
                            onChange={handleChange}
                            placeholder="Enter customer phone"
                        />
                    </label>
                    <label>
                        Shipment Type:
                        <select name="shipmentType" value={newQuote.shipmentType} onChange={handleChange}>
                            <option value="LCL">LCL</option>
                            <option value="FCL">FCL</option>
                            <option value="AIR">AIR</option>  {/* Added AIR */} 
                        </select>
                    </label>

                    {/* LCL/AIR Specific Fields */}
                    {(newQuote.shipmentType === 'LCL' || newQuote.shipmentType === 'AIR') && (
                        <>
                            <label>
                                Weight (kg):
                                <input
                                    type="number"
                                    name="weight"
                                    value={newQuote.weight}
                                    onChange={handleChange}
                                    placeholder="Enter weight"
                                />
                            </label>
                            <label>
                                Number of Packages:
                                <input
                                    type="number"
                                    name="numberOfPackages"
                                    value={newQuote.numberOfPackages}
                                    onChange={handleChange}
                                    placeholder="Enter number of packages"
                                />
                            </label>
                            <label>
                                Dimensions (cm):
                                <input
                                    type="text"
                                    name="dimensions"
                                    value={newQuote.dimensions}
                                    onChange={handleChange}
                                    placeholder="Enter dimensions (e.g. 40x50x60)"
                                />
                            </label>
                        </>
                    )}

                    {/* FCL Specific Fields */}
                    {newQuote.shipmentType === 'FCL' && (
                        <>
                            <label>
                                Container Type:
                                <input
                                    type="text"
                                    name="containerType"
                                    value={newQuote.containerType}
                                    onChange={handleChange}
                                    placeholder="Enter container type"
                                />
                            </label>
                            <label>
                                Number of Containers:
                                <input
                                    type="number"
                                    name="numberOfContainers"
                                    value={newQuote.numberOfContainers}
                                    onChange={handleChange}
                                    placeholder="Enter number of containers"
                                />
                            </label>
                        </>
                    )}

                    <label>
                        Origin:
                        <input
                            type="text"
                            name="origin"
                            value={newQuote.origin}
                            onChange={handleChange}
                            placeholder="Enter origin"
                        />
                    </label>
                    <label>
                        Destination:
                        <input
                            type="text"
                            name="destination"
                            value={newQuote.destination}
                            onChange={handleChange}
                            placeholder="Enter destination"
                        />
                    </label>
                    <label>
                        Valid Until:
                        <input
                            type="date"
                            name="validUntil"
                            value={newQuote.validUntil}
                            onChange={handleChange}
                        />
                    </label>
                    <label>
                        Total Cost:
                        <input
                            type="number"
                            name="totalCost"
                            value={newQuote.totalCost}
                            onChange={handleChange}
                            placeholder="Enter total cost"
                        />
                    </label>

                    {/* Breakdown Costs */}
                    <div>
                        <h4>Breakdown Costs</h4>
                        {newQuote.breakdownCost.map((cost, index) => (
                            <div key={index}>
                                <input
                                    type="text"
                                    value={cost.key}
                                    onChange={(e) => {
                                        const updatedCosts = [...newQuote.breakdownCost];
                                        updatedCosts[index].key = e.target.value;
                                        setNewQuote({ ...newQuote, breakdownCost: updatedCosts });
                                    }}
                                    placeholder="Cost item"
                                />
                                <input
                                    type="number"
                                    value={cost.value}
                                    onChange={(e) => {
                                        const updatedCosts = [...newQuote.breakdownCost];
                                        updatedCosts[index].value = e.target.value;
                                        setNewQuote({ ...newQuote, breakdownCost: updatedCosts });
                                    }}
                                    placeholder="Amount"
                                />
                                <button onClick={() => handleRemoveBreakdownCost(index)}>Remove</button>
                            </div>
                        ))}
                        <button onClick={handleAddBreakdownCost}>Add Breakdown Cost</button>
                    </div>

                    <button onClick={handleSubmit} className="submit-quote-button">
                        Submit Quote
                    </button>
                    <button onClick={() => setShowForm(false)} className="cancel-button">Cancel</button>
                </div>
            )}

            {renderTable()}
        </div>
    );
};

FinalQuote.propTypes = {
    query: PropTypes.shape({
        queryId: PropTypes.string,
    }).isRequired,
};

export default FinalQuote;
